@tailwind base;
@tailwind components;
@tailwind utilities;


.phoneLogin-section {
  background: url("./../../assets/images/cover.webp") no-repeat;
  background-size: cover;
  max-width: 100vw;
  height: 100vh;
  color: #fff;
  z-index: 9;
}

* {
  font-family: "Rubik", sans-serif;
}


@media only screen and (max-width: 542px) {
  .input-container {
    @apply w-[80vw];
  }

  .heading {
    font-size: 22px;
  }

  .otpInputHeading {
    font-size: 16px;
  }
}

@media only screen and (max-width: 462px) {
  .country-code {
    @apply w-[50px];
  }
}