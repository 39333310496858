@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.login-container {
  min-height: 100vh;
}
.Login-section {
  background: url('./../../assets/images/cover.webp') no-repeat;
  background-size: cover;
  max-width: 100vw;
  min-height: 100vh;
  color: #fff;
  z-index: 9;
}
@media screen and (orientation: landscape) {
  .Login-section {
    @apply py-8;
  }
}
* {
  font-family: 'Rubik', sans-serif;
}

.input-bar {
  @apply w-full h-4 font-normal rounded-full px-5 py-6 text-lg outline-none border-none text-[#595959];
}

.btn-bar {
  @apply bg-[#edae00] w-full text-center text-lg font-medium px-10 mb-4 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00];
}

@media only screen and (max-width: 622px) {
  .login-form {
    @apply max-w-[90vw];
  }

  .sso-btn {
    @apply text-base;
  }
}
