.emailConfirmation-section {
  background: url('./../../assets/images/cover.webp') no-repeat;
  background-size: cover;
  max-width: 100vw;
  height: 100vh;
  color: #fff;
  z-index: 9;
}

. {
  font-family: 'Rubik', cursive;
}

.btn-bar-email {
  @apply bg-[#edae00] w-full text-center text-lg px-10 mb-4 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00];
}

.email-form {
  @apply w-[35rem];
}

.visibility-iconOff,
.visibility-icon {
  @apply size-[17px] !important;
}

@media (max-width: 618px) {
  .email-form {
    @apply w-[90vw];
  }

  .heading {
    @apply text-3xl;
  }
}
