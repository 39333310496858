@tailwind base;
@tailwind components;
@tailwind utilities;

.signup-section {
  background: url('./../../assets/images/cover.webp') no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  color: #fff;
  z-index: 9;
}

header {
  font-family: 'Rubik', sans-serif;
}

/* Explicitly set placeholder color for date input */
input-bar[type='date']::placeholder {
  color: #595959; /* Placeholder text color */
  opacity: 1; /* Ensure the color is applied */
}

.extra-btn {
  @apply bg-[#edae00] text-xl px-5 mb-2 py-2 rounded-full hover:bg-black hover:bg-opacity-60 hover:border-[#edae00] border-2 transition-all duration-300 ease-in-out border-[#edae00];
}

@media only screen and (max-width: 542px) {
  .two-row-input {
    @apply flex-wrap gap-2 w-full;
  }

  .input-container {
    @apply w-[90vw];
  }
}

@media only screen and (max-width: 398px) {
  .label {
    font-size: 14px !important;
  }
}
@media screen and (orientation: landscape) {
  .signup-section {
    min-height: 100vh;
    @apply py-8;
  }
}

.error {
  @apply text-red-500 text-xs font-bold ml-2;
}

.pswdVisibility {
  width: 17px !important;
  height: 17px !important;
}
